@import 'site-variables';
@import 'bootstrap';
@import 'fontawesome/font-awesome';
@import 'survivor';
@import 'datetimepicker/bootstrap-datetimepicker-build';

.navbar,
.navbar-default {
  margin-bottom: 0 !important;
  background: $site-navy !important;
  border: none !important;
  text-transform: uppercase;
  border-radius: 0 !important;
}

.navbar-brand,
.navbar-toggle,
.navbar-nav > li > a {
  color: #fff !important;

  &.open,
  &:focus {
    background-color: $site-navy !important;
    color: #fff !important;
  }

  &:hover {
    color: #ccc !important;
  }
}

.navbar-default .navbar-nav .open {
  .dropdown-menu,
  .dropdown-menu > li > a {
    background-color: $site-navy !important;
    color: #fff !important;

    &:focus {
      background-color: $site-navy !important;
      color: #fff !important;
    }

    &:hover {
      color: #ccc !important;
    }
  }
}

.icon-bar {
  background: #fff !important;
}

.page-content {
  min-height: calc(100vh - 124px);
}

.footer {
  position: relative;
  width: 100%;
  height: 74px;
  background-color: #2b2b52;
  color: #fff;
  text-align: center;

  .footer-content {
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
  }

  span > a {
    color: #75dfff;
  }
}

.class-404 {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  font-size: 34px;
  text-align: center;
}