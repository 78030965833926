$fa-font-path: "../fonts/fontawesome";
$site-navy: #2b2b52;

ul {
    list-style-type: none;
}
.rules-page {
    font-size: 1.2em;
    line-height: 1.6em;
    ul {
        list-style-type: disc;

        li {
            margin-bottom: 16px;
        }
    }
}

.season-switcher {
  text-align: center;
  margin-bottom: 0;
  font-size: 24px;
  background-color: #fff;
  padding: 20px;

  @media (min-width: 992px) {
    margin-bottom: 20px;
  }

  &.past-season {
    background-color: #fff2cf;

    li,
    button {
        color: #d00000;
    }
  }
}

.main-content {
    max-width: 980px;
    margin: 0 auto;
    background-color: #fff;
    padding-top: 20px;
    padding-bottom: 20px;
}

.survivor-games {
    color: #000;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

    &.my-entry-page {
        .entry-eliminated,
        .showing-all {
            display: none;
        }
    }

    .fa {
        font-size: 1.25em;
        position: relative;
        top: 1px;

        &.fa-lock,
        &.fa-tag,
        &.fa-pencil-square-o {
            color: #1472D0;
        }

        &.fa-pencil-square-o {
            font-size: 1.8em;
        }

        &.fa-check {
            color: #03B503;
        }

        &.fa-times {
            color: #DA0909;
        }
    }

    .module-title {
        padding: 10px 14px;
        background-color: #b95f12;
        color: #fff;
        text-transform: uppercase;
    }

    .cutoff-message {
        font-weight: 600;
    }

    .entry-item {
        margin-bottom: 25px;
        padding: 20px;
        background-color: #eee;

        .entry-name {
            font-size: 1.2em;
        }

        .game-info {
            margin-bottom: 25px;
        }
    }

    .create-entry {
        margin-top: 20px;

        .deadline {
            color: red;
        }
    }

    .games {
        color: #000;
    }
    
    .game-item {
        position: relative;
        margin: 10px 0;
        min-height: 140px;
        overflow: hidden;
        border: 1px solid #ccc;
        background-color: #fff;
        white-space: nowrap;

        &.no-pick {
            opacity: .2;
        }

        &.team-selected {
            border: 2px solid #1472D0;
        }

        &.selected-winner {
            border: 2px solid #03B503;
        }

        &.selected-loser {
            border: 2px solid #DA0909;
        }

        .game-item-inner {
            position: absolute;
            width: 100%;
            height: 100%;
            padding: 10px;
        }

        .is-winner {
            font-weight: bold;
        }

        .visitor {
            margin-bottom: 15px;
        }

        .visitor, .home {
            display: table;
            width: 100%;

            .fa {
                position: relative;
            }

            .team-selected {
                border: 1px solid #1472D0;
                margin-top: -5px;
                padding-top: 5px;
            }

            .selected-winner {
                border: 1px solid #03B503;
                margin-top: -5px;
                padding-top: 5px;
            }

            .selected-loser {
                text-decoration: line-through;
                margin-top: -5px;
                padding-top: 5px;
            }

            .glyphicon {
                font-size: 1.25em;
                top: 3px;

                &.glyphicon-tag {
                    color: #1472D0;
                }

                &.glyphicon-ok {
                    color: #03B503;
                }

                &.glyphicon-remove {
                    color: #DA0909;
                }
            }
        }

        .home {
            display: table;
        }

        .logo {
            min-width: 50px;
            display: block;
        }

        .nickname {
            height: 30px;
            margin-left: 5px;
            display: block;
            white-space: nowrap;
        }

        .score {
            height: 30px;
            text-align: right;
            padding-right: 0;
            display: block;
        }

        .nickname,
        .score {
            position: relative;
            top: 5px;
        }
        .quarter {
            position: relative;
            top: 20px;
            text-align: center;
        }
    }

    .favorites {
        margin-left: 10%;
        font-weight: 100;
    }

    .radio {
        font-weight: 400;
    }

    .modal-backdrop {
        z-index: 0;
        display: none;
    }

    table.standings-table {
        background-color: #eee;
    }

    .game-ended {
        padding: 20px;
        background-color: #eee;
    }

    @media(max-width: 480px) {
        table.standings-table {
            font-size: .8em;
        }
    }

    @media(max-width: 374px) {
        .game-item {
            .nickname {
                display: none;
            }

            .quarter {
                font-size: .8em;
            }
        }
    }
}
